define('ember-inject-optional/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = topLevelExport;
  var computed = Ember.computed,
      getOwner = Ember.getOwner,
      dasherize = Ember.String.dasherize;
  function topLevelExport() {
    throw new Error('You tried to invoke the top-level export of ember-inject-optional as a function. Instead, use its service() method like:\n\nimport injectOptional from \'ember-inject-optional\';\nComponent.extend({\n  maybeMyService: injectOptional.service()\n});\n');
  }

  topLevelExport.service = function service(name) {
    return computed(function (defaultName) {
      return getOwner(this).lookup('service:' + (name || dasherize(defaultName)));
    });
  };
});