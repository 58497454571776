enifed('@glimmer/low-level', ['exports'], function (exports) {
    'use strict';

    exports.Stack = exports.Storage = undefined;

    var Storage = function () {
        function Storage() {

            this.array = [];
            this.next = 0;
        }

        Storage.prototype.add = function (element) {
            var slot = this.next,
                array = this.array,
                prev;

            if (slot === array.length) {
                this.next++;
            } else {
                prev = array[slot];

                this.next = prev;
            }
            this.array[slot] = element;
            return slot;
        };

        Storage.prototype.deref = function (pointer) {
            return this.array[pointer];
        };

        Storage.prototype.drop = function (pointer) {
            this.array[pointer] = this.next;
            this.next = pointer;
        };

        return Storage;
    }();

    var Stack = function () {
        function Stack() {
            var vec = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];


            this.vec = vec;
        }

        Stack.prototype.clone = function () {
            return new Stack(this.vec.slice());
        };

        Stack.prototype.sliceFrom = function (start) {
            return new Stack(this.vec.slice(start));
        };

        Stack.prototype.slice = function (start, end) {
            return new Stack(this.vec.slice(start, end));
        };

        Stack.prototype.copy = function (from, to) {
            this.vec[to] = this.vec[from];
        };

        Stack.prototype.writeRaw = function (pos, value) {
            // TODO: Grow?
            this.vec[pos] = value;
        };

        Stack.prototype.writeSmi = function (pos, value) {
            this.vec[pos] = encodeSmi(value);
        };

        Stack.prototype.getRaw = function (pos) {
            return this.vec[pos];
        };

        Stack.prototype.getSmi = function (pos) {
            return decodeSmi(this.vec[pos]);
        };

        Stack.prototype.reset = function () {
            this.vec.length = 0;
        };

        Stack.prototype.len = function () {
            return this.vec.length;
        };

        return Stack;
    }();

    function decodeSmi(smi) {
        switch (smi & 7) {
            case 0 /* NUMBER */:
                return smi >> 3;
            case 4 /* NEGATIVE */:
                return -(smi >> 3);
            default:
                throw new Error('unreachable');
        }
    }
    function encodeSmi(primitive) {
        if (primitive < 0) {
            return Math.abs(primitive) << 3 | 4 /* NEGATIVE */;
        } else {
            return primitive << 3 | 0 /* NUMBER */;
        }
    }

    exports.Storage = Storage;
    exports.Stack = Stack;
});