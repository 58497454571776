define("ember-froala-editor/helpers/froala-method", ["exports", "@babel/runtime/helpers/esm/toArray", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/typeof"], function (_exports, _toArray2, _toConsumableArray2, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.froalaMethod = froalaMethod;
  _exports.default = void 0;

  function froalaMethod(methodName) {
    var helperHash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    for (var _len = arguments.length, helperParams = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      helperParams[_key - 2] = arguments[_key];
    }

    (false && !(typeof methodName === 'string' && methodName.length > 0) && Ember.assert('{{froala-method}} helper needs a string method path as the first argument', typeof methodName === 'string' && methodName.length > 0)); // Create a closure to pass back as the "action" to be triggered

    return function froalaMethodClosure() {
      for (var _len2 = arguments.length, closureParams = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        closureParams[_key2] = arguments[_key2];
      }

      // Editor might be passed in as the first arg if also wrapped with {{froala-arg}}
      var editor = (0, _typeof2.default)(closureParams[0]) === 'object' && closureParams[0].component ? closureParams[0] : this;
      (false && !((0, _typeof2.default)(editor) === 'object' && editor.component) && Ember.assert('{{froala-method}} helper cannot determine the editor instance', (0, _typeof2.default)(editor) === 'object' && editor.component)); // Get the actual method using ember.get() so that nested paths work here

      var method = Ember.get(editor, methodName);
      (false && !(typeof method === 'function') && Ember.assert('{{froala-method}} helper needs a valid method path as the first argument', typeof method === 'function')); // Copy helperParams to pass into the method

      var methodParams = [].concat(helperParams); // The helper hash can be used to "replace" the param
      // values with params received from the event...

      for (var propName in helperHash) {
        // Determine the positions within each params arrays
        // which should be used for replacing. parseInt() in
        // case the value is a string integer, ex: "2".
        var helperPos = helperParams.indexOf(propName);
        var eventPos = parseInt(helperHash[propName]); // If the parameter "name" was found in the helperParams,
        // and the relative arguments contains something at that position,
        // then go ahead and replace the param value...

        if (helperPos !== -1 && closureParams.length >= eventPos) {
          methodParams[helperPos] = closureParams[eventPos - 1];
        }
      } // Trigger the Froala Editor method


      return method.apply(void 0, (0, _toConsumableArray2.default)(methodParams));
    };
  }

  var _default = Ember.Helper.helper(function (_ref, hash) {
    var _ref2 = (0, _toArray2.default)(_ref),
        methodName = _ref2[0],
        partial = _ref2.slice(1);

    return froalaMethod.apply(void 0, [methodName, hash].concat((0, _toConsumableArray2.default)(partial)));
  });

  _exports.default = _default;
});