define('ember-angle-bracket-invocation-polyfill/helpers/-link-to-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function linkToParams(_params, _ref) {
    var route = _ref.route,
        model = _ref.model,
        models = _ref.models,
        query = _ref.query;

    var params = [];

    if (route) {
      params.push(route);
    }

    if (model) {
      params.push(model);
    }

    if (models) {
      params.push.apply(params, _toConsumableArray(models));
    }

    if (query) {
      params.push({
        isQueryParams: true,
        values: query
      });
    }

    return params;
  }

  exports.default = Ember.Helper.helper(linkToParams);
});