define("ember-froala-editor/helpers/froala-arg", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.froalaArg = froalaArg;
  _exports.default = void 0;

  function froalaArg(callback) {
    for (var _len = arguments.length, partial = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      partial[_key - 1] = arguments[_key];
    }

    (false && !(typeof callback === 'function') && Ember.assert('{{froala-arg}} helper requires a function as the first parameter', typeof callback === 'function'));

    var wrapper = function froalaArgClosure() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return callback.apply(void 0, [this].concat(partial, args));
    };

    (false && !(wrapper.toString() !== callback.toString()) && Ember.assert('{{froala-arg}} helper should not be call twice on the same callback', wrapper.toString() !== callback.toString()));
    return wrapper;
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
        callback = _ref2[0],
        partial = _ref2.slice(1);

    return froalaArg.apply(void 0, [callback].concat((0, _toConsumableArray2.default)(partial)));
  });

  _exports.default = _default;
});