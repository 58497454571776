define("ember-masonry-grid/components/masonry-grid/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-diff-attrs", "ember-masonry-grid/components/masonry-grid/template"], function (_exports, _toConsumableArray2, _emberDiffAttrs, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var A = Ember.A,
      Component = Ember.Component,
      computed = Ember.computed,
      alias = Ember.computed.alias,
      defineProperty = Ember.defineProperty,
      getProperties = Ember.getProperties,
      get = Ember.get,
      service = Ember.inject.service,
      scheduleOnce = Ember.run.scheduleOnce,
      set = Ember.set,
      htmlSafe = Ember.String.htmlSafe;
  var MASONRY_OPTION_KEYS = A(['containerStyle', 'columnWidth', 'gutter', 'hiddenStyle', 'isFitWidth', 'isInitLayout', 'isOriginLeft', 'isOriginTop', 'isResizeBound', 'itemSelector', 'stamp', 'transitionDuration', 'visibleStyle']);

  var _default = Component.extend({
    layout: _template.default,
    classNames: ['masonry-grid'],
    masonryConfig: service(),
    config: alias('masonryConfig.config'),
    // masonry default options
    // overriding the default `isInitLayout` value allows us to attach an event for
    // `layoutComplete` before the first render
    isInitLayout: false,
    itemSelector: '.masonry-item',
    attributeBindings: ['masonryGridStyle:style'],
    masonryGridStyle: htmlSafe('position: relative'),
    customLayout: false,
    masonry: null,
    itemClass: computed('itemSelector', function () {
      return get(this, 'itemSelector').replace('.', '');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      defineProperty(this, 'options', computed.apply(this, [].concat((0, _toConsumableArray2.default)(MASONRY_OPTION_KEYS), [this._computeOptions])));
    },
    didUpdateAttrs: (0, _emberDiffAttrs.default)({
      keys: MASONRY_OPTION_KEYS,
      hook: function hook(changedAttrs) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        this._super.apply(this, args);

        this._destroyMasonry();
      }
    }),
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      var masonry = get(this, 'masonry');
      this.executeAfter(function () {
        if (masonry) {
          masonry.reloadItems();
        } else {
          var options = get(_this, 'options');
          masonry = set(_this, 'masonry', new Masonry(get(_this, 'element'), options));
          masonry.on('layoutComplete', function (layout) {
            if (!get(_this, 'isDestroyed') && !get(_this, 'isDestroying')) {
              _this.sendAction('onLayoutComplete', layout);
            }
          });
        }

        masonry.layout();
      });
    },
    executeAfter: function executeAfter(cb) {
      var _this2 = this;

      scheduleOnce('afterRender', this, function () {
        if (get(_this2, 'config.imagesLoaded') === false) {
          cb.call(_this2);
        } else {
          imagesLoaded(get(_this2, 'element'), function () {
            cb.call(_this2);
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._destroyMasonry();
    },
    _computeOptions: function _computeOptions() {
      var options = getProperties(this, MASONRY_OPTION_KEYS);
      Object.keys(options).forEach(function (key) {
        if (options[key] === 'null') {
          options[key] = null;
        }

        if (options[key] === undefined) {
          delete options[key];
        }
      });
      return options;
    },
    _destroyMasonry: function _destroyMasonry() {
      var masonry = get(this, 'masonry');

      if (masonry) {
        masonry.destroy();
      }

      set(this, 'masonry', undefined);
    }
  });

  _exports.default = _default;
});