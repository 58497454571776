define("@babel/runtime/helpers/esm/createSuper", ["exports", "@babel/runtime/helpers/esm/getPrototypeOf", "@babel/runtime/helpers/esm/isNativeReflectConstruct", "@babel/runtime/helpers/esm/possibleConstructorReturn"], function (_exports, _getPrototypeOf, _isNativeReflectConstruct, _possibleConstructorReturn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _createSuper;

  function _createSuper(Derived) {
    return function () {
      var Super = (0, _getPrototypeOf.default)(Derived),
          result;

      if ((0, _isNativeReflectConstruct.default)()) {
        var NewTarget = (0, _getPrototypeOf.default)(this).constructor;
        result = Reflect.construct(Super, arguments, NewTarget);
      } else {
        result = Super.apply(this, arguments);
      }

      return (0, _possibleConstructorReturn.default)(this, result);
    };
  }
});