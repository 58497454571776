define("ember-fn-helper-polyfill/helpers/fn", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HAS_NATIVE_PROXY = typeof Proxy === 'function';
  var INVOKE = 'invoke'; // TODO: this is wrong

  var context = function buildUntouchableThis() {
    var context = null;

    if (false
    /* DEBUG */
    && HAS_NATIVE_PROXY) {
      var assertOnProperty = function assertOnProperty(property) {
        (false && !(false) && Ember.assert("You accessed `this.".concat(String(property), "` from a function passed to the `fn` helper, but the function itself was not bound to a valid `this` context. Consider updating to usage of `@action`.")));
      };

      context = new Proxy({}, {
        get: function get(_target, property) {
          assertOnProperty(property);
        },
        set: function set(_target, property) {
          assertOnProperty(property);
          return false;
        },
        has: function has(_target, property) {
          assertOnProperty(property);
          return false;
        }
      });
    }

    return context;
  }();

  var _default = Ember.Helper.extend({
    init: function init() {
      this._super();

      this._positional = null;
      this._fn = null;
    },
    compute: function compute(positional) {
      var _this = this;

      (false && !(typeof positional[0] === 'function') && Ember.assert("You must pass a function as the `fn` helpers first argument, you passed ".concat(positional[0]), typeof positional[0] === 'function'));
      this._positional = positional;

      if (this._fn === null) {
        this._fn = function () {
          var _this$_positional = (0, _toArray2.default)(_this._positional),
              fn = _this$_positional[0],
              args = _this$_positional.slice(1); // TODO: fix this branch


          for (var _len = arguments.length, invocationArgs = new Array(_len), _key = 0; _key < _len; _key++) {
            invocationArgs[_key] = arguments[_key];
          }

          if (typeof fn[INVOKE] === 'function') {
            // references with the INVOKE symbol expect the function behind
            // the symbol to be bound to the reference
            return fn[INVOKE].apply(fn, (0, _toConsumableArray2.default)(args).concat(invocationArgs));
          } else {
            return fn.call.apply(fn, [context].concat((0, _toConsumableArray2.default)(args), invocationArgs));
          }
        };
      }

      return this._fn;
    }
  });

  _exports.default = _default;
});