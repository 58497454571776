define("ember-sortablejs/components/sortable-js", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "sortablejs", "ember-diff-attrs", "ember-sortablejs/templates/components/sortable-js"], function (_exports, _slicedToArray2, _sortablejs, _emberDiffAttrs, _sortableJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var freeze = Object.freeze;

  var _default = Ember.Component.extend({
    layout: _sortableJs.default,
    classNames: ['ember-sortable-js'],
    options: null,
    events: freeze(['onChoose', 'onUnchoose', 'onStart', 'onEnd', 'onAdd', 'onUpdate', 'onSort', 'onRemove', 'onMove', 'onClone', 'onChange', 'scrollFn', 'onSetData', 'setData', 'onFilter']),
    didReceiveAttrs: (0, _emberDiffAttrs.default)('options', function (changedAttrs) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      if (changedAttrs && changedAttrs.options) {
        var options = changedAttrs.options.pop();

        for (var _i = 0, _Object$entries = Object.entries(options); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = (0, _slicedToArray2.default)(_Object$entries[_i], 2),
              key = _Object$entries$_i[0],
              value = _Object$entries$_i[1];

          this.setOptions(key, value);
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var el = this.element.firstElementChild;
      var defaults = {};
      var options = Object.assign({}, defaults, this.options);
      this.sortable = _sortablejs.default.create(el, options);
      this.setupEventHandlers();
    },
    willDestroyElement: function willDestroyElement() {
      this.sortable.destroy();

      this._super.apply(this, arguments);
    },
    setupEventHandlers: function setupEventHandlers() {
      var _this = this;

      this.events.forEach(function (eventName) {
        var action = _this[eventName];

        if (typeof action === 'function') {
          _this.sortable.option(eventName, Ember.run.bind(_this, 'performExternalAction', eventName));
        }
      });
    },
    performExternalAction: function performExternalAction(actionName) {
      var action = this[actionName];
      action = action === 'onSetData' ? 'setData' : action;

      if (typeof action === 'function') {
        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }

        action.apply(void 0, args.concat([this.sortable]));
      }
    },
    setOptions: function setOptions(option, value) {
      this.sortable.option(option, value);
    }
  });

  _exports.default = _default;
});