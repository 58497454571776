define("ember-composable-helpers/-private/create-multi-array-helper", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  var idForArray = function idForArray(array) {
    return "__array-".concat(Ember.guidFor(array));
  };

  function _default(multiArrayComputed) {
    return Ember.Helper.extend({
      compute: function compute(_ref) {
        var _ref2 = (0, _toArray2.default)(_ref),
            arrays = _ref2.slice(0);

        Ember.set(this, 'arrays', arrays.map(function (obj) {
          if (Ember.isArray(obj)) {
            return Ember.A(obj);
          }

          return obj;
        }));
        return Ember.get(this, 'content');
      },
      valuesDidChange: Ember.observer('arrays.[]', function () {
        this._recomputeArrayKeys();

        var arrays = Ember.get(this, 'arrays');
        var arrayKeys = Ember.get(this, 'arrayKeys');

        if (Ember.isEmpty(arrays)) {
          Ember.defineProperty(this, 'content', []);
          return;
        }

        Ember.defineProperty(this, 'content', multiArrayComputed.apply(void 0, (0, _toConsumableArray2.default)(arrayKeys)));
      }),
      contentDidChange: Ember.observer('content.[]', function () {
        this.recompute();
      }),
      _recomputeArrayKeys: function _recomputeArrayKeys() {
        var _this = this;

        var arrays = Ember.get(this, 'arrays');
        var oldArrayKeys = Ember.get(this, 'arrayKeys') || [];
        var newArrayKeys = arrays.map(idForArray);
        var keysToRemove = oldArrayKeys.filter(function (key) {
          return newArrayKeys.indexOf(key) === -1;
        });
        keysToRemove.forEach(function (key) {
          return Ember.set(_this, key, null);
        });
        arrays.forEach(function (array) {
          return Ember.set(_this, idForArray(array), array);
        });
        Ember.set(this, 'arrayKeys', newArrayKeys);
      }
    });
  }
});