define("ember-froala-editor/helpers/to-string", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toString = toString;
  _exports.default = void 0;

  function toString(context) {
    for (var _len = arguments.length, partial = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      partial[_key - 1] = arguments[_key];
    }

    // @update={{to-string (fn (mut this.content))}}
    if (typeof context === 'function') {
      return function toStringClosure(content) {
        var contentString = content && typeof content.toString === 'function' ? content.toString() : content;

        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }

        return context.apply(void 0, [contentString].concat(partial, args));
      }; // {{to-string this.content}}
    } else {
      return context && typeof context.toString === 'function' ? context.toString() : context;
    }
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
        context = _ref2[0],
        partial = _ref2.slice(1);

    return toString.apply(void 0, [context].concat((0, _toConsumableArray2.default)(partial)));
  });

  _exports.default = _default;
});