enifed('ember-meta/lib/meta', ['exports', 'ember-babel', '@ember/debug', 'ember-utils'], function (exports, _emberBabel, _debug, _emberUtils) {
    'use strict';

    exports.counters = exports.meta = exports.Meta = exports.UNDEFINED = undefined;
    exports.setMeta = setMeta;
    exports.peekMeta = peekMeta;
    exports.deleteMeta =
    /**
      Tears down the meta on an object so that it can be garbage collected.
      Multiple calls will have no effect.
    
      @method deleteMeta
      @for Ember
      @param {Object} obj  the object to destroy
      @return {void}
      @private
    */
    function (obj) {
        false && !(obj !== null) && (0, _debug.assert)('Cannot call `deleteMeta` on null', obj !== null);
        false && !(obj !== undefined) && (0, _debug.assert)('Cannot call `deleteMeta` on undefined', obj !== undefined);
        false && !(typeof obj === 'object' || typeof obj === 'function') && (0, _debug.assert)('Cannot call `deleteMeta` on ' + typeof obj, typeof obj === 'object' || typeof obj === 'function');

        var meta = peekMeta(obj);
        if (meta !== undefined) {
            meta.destroy();
        }
    }
    /**
      Retrieves the meta hash for an object. If `writable` is true ensures the
      hash is writable for this object as well.
    
      The meta object contains information about computed property descriptors as
      well as any watched properties and other information. You generally will
      not access this information directly but instead work with higher level
      methods that manipulate this hash indirectly.
    
      @method meta
      @for Ember
      @private
    
      @param {Object} obj The object to retrieve meta for
      @param {Boolean} [writable=true] Pass `false` if you do not intend to modify
        the meta hash, allowing the method to avoid making an unnecessary copy.
      @return {Object} the meta hash for an object
    */
    ;
    exports.descriptorFor =
    /**
      Returns the CP descriptor assocaited with `obj` and `keyName`, if any.
    
      @method descriptorFor
      @param {Object} obj the object to check
      @param {String} keyName the key to check
      @return {Descriptor}
      @private
    */
    function (obj, keyName, _meta) {
        false && !(obj !== null) && (0, _debug.assert)('Cannot call `descriptorFor` on null', obj !== null);
        false && !(obj !== undefined) && (0, _debug.assert)('Cannot call `descriptorFor` on undefined', obj !== undefined);
        false && !(typeof obj === 'object' || typeof obj === 'function') && (0, _debug.assert)('Cannot call `descriptorFor` on ' + typeof obj, typeof obj === 'object' || typeof obj === 'function');

        var meta = _meta === undefined ? peekMeta(obj) : _meta;
        if (meta !== undefined) {
            return meta.peekDescriptors(keyName);
        }
    }
    /**
      Check whether a value is a CP descriptor.
    
      @method descriptorFor
      @param {any} possibleDesc the value to check
      @return {boolean}
      @private
    */
    ;
    exports.isDescriptor = function (possibleDesc) {
        // TODO make this return `possibleDesc is Descriptor`
        return possibleDesc !== undefined && possibleDesc !== null && typeof possibleDesc === 'object' && possibleDesc.isDescriptor === true;
    };

    var objectPrototype = Object.prototype;

    /**
    @module ember
    */
    var UNDEFINED = exports.UNDEFINED = (0, _emberUtils.symbol)('undefined');
    var currentListenerVersion = 1;

    var Meta = exports.Meta = function () {
        function Meta(obj) {

            this._listenersVersion = 1;
            this._inheritedEnd = -1;
            this._flattenedVersion = 0;

            this._parent = undefined;
            this._descriptors = undefined;
            this._watching = undefined;
            this._mixins = undefined;
            this._deps = undefined;
            this._chainWatchers = undefined;
            this._chains = undefined;
            this._tag = undefined;
            this._tags = undefined;
            // initial value for all flags right now is false
            // see FLAGS const for detailed list of flags used
            this._flags = 0 /* NONE */;
            // used only internally
            this.source = obj;
            this.proto = obj.constructor === undefined ? undefined : obj.constructor.prototype;
            this._listeners = undefined;
        }

        Meta.prototype.setInitializing = function () {
            this._flags |= 8 /* INITIALIZING */;
        };

        Meta.prototype.unsetInitializing = function () {
            this._flags ^= 8 /* INITIALIZING */;
        };

        Meta.prototype.isInitializing = function () {
            return this._hasFlag(8 /* INITIALIZING */);
        };

        Meta.prototype.isPrototypeMeta = function (obj) {
            return this.proto === this.source && this.source === obj;
        };

        Meta.prototype.destroy = function () {
            if (this.isMetaDestroyed()) {
                return;
            }
            this.setMetaDestroyed();
            // remove chainWatchers to remove circular references that would prevent GC
            var chains = this.readableChains();
            if (chains !== undefined) {
                chains.destroy();
            }
        };

        Meta.prototype.isSourceDestroying = function () {
            return this._hasFlag(1 /* SOURCE_DESTROYING */);
        };

        Meta.prototype.setSourceDestroying = function () {
            this._flags |= 1 /* SOURCE_DESTROYING */;
        };

        Meta.prototype.isSourceDestroyed = function () {
            return this._hasFlag(2 /* SOURCE_DESTROYED */);
        };

        Meta.prototype.setSourceDestroyed = function () {
            this._flags |= 2 /* SOURCE_DESTROYED */;
        };

        Meta.prototype.isMetaDestroyed = function () {
            return this._hasFlag(4 /* META_DESTROYED */);
        };

        Meta.prototype.setMetaDestroyed = function () {
            this._flags |= 4 /* META_DESTROYED */;
        };

        Meta.prototype._hasFlag = function (flag) {
            return (this._flags & flag) === flag;
        };

        Meta.prototype._getOrCreateOwnMap = function (key) {
            return this[key] || (this[key] = Object.create(null));
        };

        Meta.prototype._getOrCreateOwnSet = function (key) {
            return this[key] || (this[key] = new Set());
        };

        Meta.prototype._findInherited1 = function (key) {
            var pointer = this,
                map;
            while (pointer !== null) {
                map = pointer[key];

                if (map !== undefined) {
                    return map;
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype._findInherited2 = function (key, subkey) {
            var pointer = this,
                map,
                value;
            while (pointer !== null) {
                map = pointer[key];

                if (map !== undefined) {
                    value = map[subkey];

                    if (value !== undefined) {
                        return value;
                    }
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype._findInherited3 = function (key, subkey, subsubkey) {
            var pointer = this,
                map,
                submap,
                value;
            while (pointer !== null) {
                map = pointer[key];

                if (map !== undefined) {
                    submap = map[subkey];

                    if (submap !== undefined) {
                        value = submap[subsubkey];

                        if (value !== undefined) {
                            return value;
                        }
                    }
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype._hasInInheritedSet = function (key, value) {
            var pointer = this,
                set;
            while (pointer !== null) {
                set = pointer[key];

                if (set !== undefined && set.has(value)) {
                    return true;
                }
                pointer = pointer.parent;
            }
            return false;
        };
        // Implements a member that provides a lazily created map of maps,
        // with inheritance at both levels.


        Meta.prototype.writeDeps = function (subkey, itemkey, count) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot modify dependent keys for `' + itemkey + '` on `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var outerMap = this._getOrCreateOwnMap('_deps');
            var innerMap = outerMap[subkey];
            if (innerMap === undefined) {
                innerMap = outerMap[subkey] = Object.create(null);
            }
            innerMap[itemkey] = count;
        };

        Meta.prototype.peekDeps = function (subkey, itemkey) {
            var val = this._findInherited3('_deps', subkey, itemkey);
            return val === undefined ? 0 : val;
        };

        Meta.prototype.hasDeps = function (subkey) {
            var val = this._findInherited2('_deps', subkey);
            return val !== undefined;
        };

        Meta.prototype.forEachInDeps = function (subkey, fn) {
            var pointer = this,
                map,
                innerMap,
                i;
            var seen = void 0;
            var calls = void 0;
            while (pointer !== null) {
                map = pointer._deps;

                if (map !== undefined) {
                    innerMap = map[subkey];

                    if (innerMap !== undefined) {
                        for (var innerKey in innerMap) {
                            seen = seen === undefined ? new Set() : seen;
                            if (!seen.has(innerKey)) {
                                seen.add(innerKey);
                                if (innerMap[innerKey] > 0) {
                                    calls = calls || [];
                                    calls.push(innerKey);
                                }
                            }
                        }
                    }
                }
                pointer = pointer.parent;
            }
            if (calls !== undefined) {
                for (i = 0; i < calls.length; i++) {
                    fn(calls[i]);
                }
            }
        };

        Meta.prototype.writableTags = function () {
            return this._getOrCreateOwnMap('_tags');
        };

        Meta.prototype.readableTags = function () {
            return this._tags;
        };

        Meta.prototype.writableTag = function (create) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot create a new tag for `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var ret = this._tag;
            if (ret === undefined) {
                ret = this._tag = create(this.source);
            }
            return ret;
        };

        Meta.prototype.readableTag = function () {
            return this._tag;
        };

        Meta.prototype.writableChainWatchers = function (create) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot create a new chain watcher for `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var ret = this._chainWatchers;
            if (ret === undefined) {
                ret = this._chainWatchers = create(this.source);
            }
            return ret;
        };

        Meta.prototype.readableChainWatchers = function () {
            return this._chainWatchers;
        };

        Meta.prototype.writableChains = function (create) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot create a new chains for `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());
            var ret = this._chains,
                parent,
                parentChains;

            if (ret === undefined) {
                this._chains = ret = create(this.source);
                parent = this.parent;


                if (parent !== null) {
                    parentChains = parent.writableChains(create);

                    parentChains.copyTo(ret);
                }
            }
            return ret;
        };

        Meta.prototype.readableChains = function () {
            return this._findInherited1('_chains');
        };

        Meta.prototype.writeWatching = function (subkey, value) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot update watchers for `' + subkey + '` on `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var map = this._getOrCreateOwnMap('_watching');
            map[subkey] = value;
        };

        Meta.prototype.peekWatching = function (subkey) {
            var count = this._findInherited2('_watching', subkey);
            return count === undefined ? 0 : count;
        };

        Meta.prototype.addMixin = function (mixin) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot add mixins of `' + (0, _emberUtils.toString)(mixin) + '` on `' + (0, _emberUtils.toString)(this.source) + '` call addMixin after it has been destroyed.' : '', !this.isMetaDestroyed());

            var set = this._getOrCreateOwnSet('_mixins');
            set.add(mixin);
        };

        Meta.prototype.hasMixin = function (mixin) {
            return this._hasInInheritedSet('_mixins', mixin);
        };

        Meta.prototype.forEachMixins = function (fn) {
            var pointer = this,
                set;
            var seen = void 0;
            while (pointer !== null) {
                set = pointer._mixins;

                if (set !== undefined) {
                    seen = seen === undefined ? new Set() : seen;
                    // TODO cleanup typing here
                    set.forEach(function (mixin) {
                        if (!seen.has(mixin)) {
                            seen.add(mixin);
                            fn(mixin);
                        }
                    });
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype.writeDescriptors = function (subkey, value) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot update descriptors for `' + subkey + '` on `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var map = this._getOrCreateOwnMap('_descriptors');
            map[subkey] = value;
        };

        Meta.prototype.peekDescriptors = function (subkey) {
            var possibleDesc = this._findInherited2('_descriptors', subkey);
            return possibleDesc === UNDEFINED ? undefined : possibleDesc;
        };

        Meta.prototype.removeDescriptors = function (subkey) {
            this.writeDescriptors(subkey, UNDEFINED);
        };

        Meta.prototype.forEachDescriptors = function (fn) {
            var pointer = this,
                map,
                value;
            var seen = void 0;
            while (pointer !== null) {
                map = pointer._descriptors;

                if (map !== undefined) {
                    for (var key in map) {
                        seen = seen === undefined ? new Set() : seen;
                        if (!seen.has(key)) {
                            seen.add(key);
                            value = map[key];

                            if (value !== UNDEFINED) {
                                fn(key, value);
                            }
                        }
                    }
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype.addToListeners = function (eventName, target, method, once) {
            this.pushListener(eventName, target, method, once ? 1 /* ONCE */ : 0 /* ADD */);
        };

        Meta.prototype.removeFromListeners = function (eventName, target, method) {
            this.pushListener(eventName, target, method, 2 /* REMOVE */);
        };

        Meta.prototype.removeAllListeners = function (event) {
            false && !false && (0, _debug.deprecate)('The remove all functionality of removeListener and removeObserver has been deprecated. Remove each listener/observer individually instead.', false, {
                id: 'events.remove-all-listeners',
                until: '3.9.0',
                url: 'https://emberjs.com/deprecations/v3.x#toc_events-remove-all-listeners'
            });

            var listeners = this.writableListeners(),
                i,
                listener;
            var inheritedEnd = this._inheritedEnd;
            // remove all listeners of event name
            // adjusting the inheritedEnd if listener is below it
            for (i = listeners.length - 1; i >= 0; i--) {
                listener = listeners[i];

                if (listener.event === event) {
                    listeners.splice(i, 1);
                    if (i < inheritedEnd) {
                        inheritedEnd--;
                    }
                }
            }
            this._inheritedEnd = inheritedEnd;
            // we put remove alls at start because rare and easy to check there
            listeners.splice(inheritedEnd, 0, {
                event: event,
                target: null,
                method: null,
                kind: 3 /* REMOVE_ALL */
            });
        };

        Meta.prototype.pushListener = function (event, target, method, kind) {
            var listeners = this.writableListeners(),
                listener;
            var i = indexOfListener(listeners, event, target, method);
            // remove if found listener was inherited
            if (i !== -1 && i < this._inheritedEnd) {
                listeners.splice(i, 1);
                this._inheritedEnd--;
                i = -1;
            }
            // if not found, push. Note that we must always push if a listener is not
            // found, even in the case of a function listener remove, because we may be
            // attempting to add or remove listeners _before_ flattening has occured.
            if (i === -1) {
                false && !!(this.isPrototypeMeta(this.source) && typeof method === 'function') && (0, _debug.deprecate)('Adding function listeners to prototypes has been deprecated. Convert the listener to a string listener, or add it to the instance instead.', !(this.isPrototypeMeta(this.source) && typeof method === 'function'), {
                    id: 'events.inherited-function-listeners',
                    until: '3.9.0',
                    url: 'https://emberjs.com/deprecations/v3.x#toc_events-inherited-function-listeners'
                });
                false && !!(!this.isPrototypeMeta(this.source) && typeof method === 'function' && kind === 2 /* REMOVE */) && (0, _debug.deprecate)('You attempted to remove a function listener which did not exist on the instance, which means it was an inherited prototype listener, or you attempted to remove it before it was added. Prototype function listeners have been deprecated, and attempting to remove a non-existent function listener this will error in the future.', !(!this.isPrototypeMeta(this.source) && typeof method === 'function' && kind === 2), {
                    id: 'events.inherited-function-listeners',
                    until: '3.9.0',
                    url: 'https://emberjs.com/deprecations/v3.x#toc_events-inherited-function-listeners'
                });

                listeners.push({
                    event: event,
                    target: target,
                    method: method,
                    kind: kind
                });
            } else {
                listener = listeners[i];
                // If the listener is our own function listener and we are trying to
                // remove it, we want to splice it out entirely so we don't hold onto a
                // reference.

                if (kind === 2 /* REMOVE */ && listener.kind !== 2 /* REMOVE */ && typeof method === 'function') {
                    listeners.splice(i, 1);
                } else {
                    // update own listener
                    listener.kind = kind;
                    // TODO: Remove this when removing REMOVE_ALL, it won't be necessary
                    listener.target = target;
                    listener.method = method;
                }
            }
        };

        Meta.prototype.writableListeners = function () {
            // Check if we need to invalidate and reflatten. We need to do this if we
            // have already flattened (flattened version is the current version) and
            // we are either writing to a prototype meta OR we have never inherited, and
            // may have cached the parent's listeners.
            if (this._flattenedVersion === currentListenerVersion && (this.source === this.proto || this._inheritedEnd === -1)) {
                currentListenerVersion++;
            }
            // Inherited end has not been set, then we have never created our own
            // listeners, but may have cached the parent's
            if (this._inheritedEnd === -1) {
                this._inheritedEnd = 0;
                this._listeners = [];
            }
            return this._listeners;
        };
        /**
          Flattening is based on a global revision counter. If the revision has
          bumped it means that somewhere in a class inheritance chain something has
          changed, so we need to reflatten everything. This can only happen if:
             1. A meta has been flattened (listener has been called)
          2. The meta is a prototype meta with children who have inherited its
             listeners
          3. A new listener is subsequently added to the meta (e.g. via `.reopen()`)
             This is a very rare occurence, so while the counter is global it shouldn't
          be updated very often in practice.
        */

        Meta.prototype.flattenedListeners = function () {
            var parent, parentListeners, listeners, i, listener, index;

            if (this._flattenedVersion < currentListenerVersion) {
                parent = this.parent;

                if (parent !== null) {
                    // compute
                    parentListeners = parent.flattenedListeners();

                    if (parentListeners !== undefined) {
                        if (this._listeners === undefined) {
                            this._listeners = parentListeners;
                        } else {
                            listeners = this._listeners;

                            if (this._inheritedEnd > 0) {
                                listeners.splice(0, this._inheritedEnd);
                                this._inheritedEnd = 0;
                            }
                            for (i = 0; i < parentListeners.length; i++) {
                                listener = parentListeners[i];
                                index = indexOfListener(listeners, listener.event, listener.target, listener.method);

                                if (index === -1) {
                                    listeners.unshift(listener);
                                    this._inheritedEnd++;
                                }
                            }
                        }
                    }
                }
                this._flattenedVersion = currentListenerVersion;
            }
            return this._listeners;
        };

        Meta.prototype.matchingListeners = function (eventName) {
            var listeners = this.flattenedListeners(),
                index,
                listener;
            var result = void 0;

            if (listeners !== undefined) {
                for (index = 0; index < listeners.length; index++) {
                    listener = listeners[index];
                    // REMOVE and REMOVE_ALL listeners are placeholders that tell us not to
                    // inherit, so they never match. Only ADD and ONCE can match.

                    if (listener.event === eventName && (listener.kind === 0 /* ADD */ || listener.kind === 1 /* ONCE */)) {
                        if (result === undefined) {
                            // we create this array only after we've found a listener that
                            // matches to avoid allocations when no matches are found.
                            result = [];
                        }
                        result.push(listener.target, listener.method, listener.kind === 1 /* ONCE */);
                    }
                }
            }
            return result;
        };

        (0, _emberBabel.createClass)(Meta, [{
            key: 'parent',
            get: function () {
                var parent = this._parent,
                    proto;
                if (parent === undefined) {
                    proto = getPrototypeOf(this.source);

                    this._parent = parent = proto === null || proto === objectPrototype ? null : meta(proto);
                }
                return parent;
            }
        }]);
        return Meta;
    }();

    var getPrototypeOf = Object.getPrototypeOf;
    var metaStore = new WeakMap();
    function setMeta(obj, meta) {
        false && !(obj !== null) && (0, _debug.assert)('Cannot call `setMeta` on null', obj !== null);
        false && !(obj !== undefined) && (0, _debug.assert)('Cannot call `setMeta` on undefined', obj !== undefined);
        false && !(typeof obj === 'object' || typeof obj === 'function') && (0, _debug.assert)('Cannot call `setMeta` on ' + typeof obj, typeof obj === 'object' || typeof obj === 'function');

        metaStore.set(obj, meta);
    }
    function peekMeta(obj) {
        false && !(obj !== null) && (0, _debug.assert)('Cannot call `peekMeta` on null', obj !== null);
        false && !(obj !== undefined) && (0, _debug.assert)('Cannot call `peekMeta` on undefined', obj !== undefined);
        false && !(typeof obj === 'object' || typeof obj === 'function') && (0, _debug.assert)('Cannot call `peekMeta` on ' + typeof obj, typeof obj === 'object' || typeof obj === 'function');

        var meta = metaStore.get(obj);
        if (meta !== undefined) {
            return meta;
        }
        var pointer = getPrototypeOf(obj);
        while (pointer !== undefined && pointer !== null) {
            meta = metaStore.get(pointer);
            if (meta !== undefined) {
                if (meta.proto !== pointer) {
                    // The meta was a prototype meta which was not marked as initializing.
                    // This can happen when a prototype chain was created manually via
                    // Object.create() and the source object does not have a constructor.
                    meta.proto = pointer;
                }
                return meta;
            }
            pointer = getPrototypeOf(pointer);
        }
    }var meta = exports.meta = function (obj) {
        false && !(obj !== null) && (0, _debug.assert)('Cannot call `meta` on null', obj !== null);
        false && !(obj !== undefined) && (0, _debug.assert)('Cannot call `meta` on undefined', obj !== undefined);
        false && !(typeof obj === 'object' || typeof obj === 'function') && (0, _debug.assert)('Cannot call `meta` on ' + typeof obj, typeof obj === 'object' || typeof obj === 'function');

        var maybeMeta = peekMeta(obj);
        // remove this code, in-favor of explicit parent
        if (maybeMeta !== undefined && maybeMeta.source === obj) {
            return maybeMeta;
        }
        var newMeta = new Meta(obj);
        setMeta(obj, newMeta);
        return newMeta;
    };
    exports.counters = void 0;

    function indexOfListener(listeners, event, target, method) {
        var i, listener;

        for (i = listeners.length - 1; i >= 0; i--) {
            listener = listeners[i];

            if (listener.event === event && (listener.target === target && listener.method === method || listener.kind === 3 /* REMOVE_ALL */)) {
                return i;
            }
        }
        return -1;
    }
});