define("ember-masonry-grid/services/masonry-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Service = Ember.Service;

  var _default = Service.extend({});

  _exports.default = _default;
});