define("ember-froala-editor/helpers/merged-hash", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mergedHash = mergedHash;
  _exports.default = void 0;

  function mergedHash() {
    for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }

    // Assumes all params are objects
    return Ember.assign.apply(void 0, [{}].concat(objects));
  }

  var _default = Ember.Helper.helper(function (params, hash) {
    return mergedHash.apply(void 0, (0, _toConsumableArray2.default)(params).concat([hash]));
  });

  _exports.default = _default;
});