define("ember-masonry-grid/components/masonry-item/component", ["exports", "ember-masonry-grid/components/masonry-item/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get,
      htmlSafe = Ember.String.htmlSafe;

  var _default = Component.extend({
    layout: _template.default,
    classNameBindings: ['itemClass'],
    attributeBindings: ['masonryItemStyle:style'],
    masonryItemStyle: htmlSafe('position: absolute'),
    itemClass: computed.oneWay('grid.itemClass'),
    click: function click(ev) {
      var onItemClick = get(this, 'onItemClick');
      var item = get(this, 'item');

      if (onItemClick && typeof onItemClick === 'function') {
        onItemClick(ev, item);
      }
    }
  });

  _exports.default = _default;
});